<template>
  <b-modal
    id="modal-quize-available"
    v-model="show"
    title="Chọn câu hỏi có sẵn"
    centered
    size="lg"
    hide-footer
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <b-row>
          <b-col>
            <basic-select
              label="Chọn cấp độ"
              placeholder="--- Chọn cấp độ liên quan đến câu hỏi ---"
              name="level"
              :solid="false"
              :allowEmpty="true"
              value-label="text"
              track-by="value"
              changeValueByObject
              :options="levels"
              :value.sync="selectedLevel"
            />
          </b-col>
          <b-col>
            <basic-select
              label="Chọn chủ đề"
              placeholder="--- Chọn chủ đề liên quan đến câu hỏi ---"
              name="module"
              :solid="false"
              :allowEmpty="true"
              value-label="text"
              track-by="value"
              changeValueByObject
              :options="lessons"
              :value.sync="selectedLesson"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-select
              label="Chọn bài học"
              placeholder="--- Chọn bài học liên quan đến câu hỏi ---"
              name="lesson"
              :solid="false"
              :allowEmpty="true"
              value-label="text"
              track-by="value"
              changeValueByObject
              :options="modules"
              :value.sync="selectedModule"
            />
          </b-col>
          <b-col>
            <basic-select
              label="Chọn câu hỏi"
              placeholder="--- Chọn câu hỏi ---"
              name="question"
              required
              :solid="false"
              :allowEmpty="true"
              value-label="name"
              track-by="id"
              changeValueByObject
              :options="questions"
              :value.sync="selectedQuestion"
              :state="validateState('question')"
              :invalidFeedback="errors.first('question')"
              v-validate="{
                required: true,
              }"
              data-vv-as="Chọn câu hỏi"
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end align-items-center">
          <b-button class="btn" type="button" @click="resetModal">
            Huỷ
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Chọn
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('survey');
export default {
  name: 'ModalQuizeAvailable',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      levels: [],
      lessons: [],
      modules: [],
      questions: [],
      selectedLevel: null,
      selectedLesson: null,
      selectedModule: null,
      selectedQuestion: null,
    };
  },
  async created() {
    await Promise.all([this.getPrepare(), this.getQuestions()]);
  },
  computed: {
    ...mapState({ storedQuestions: 'questions' }),
    searchQuestion() {
      return {
        lessonLevelId: this.selectedLevel?.value || null,
        lessonModuleId: this.selectedModule?.value || null,
        lessonId: this.selectedLesson?.value || null,
        status: 1,
      };
    },
  },
  watch: {
    selectedLevel: {
      handler() {
        this.getQuestions();
      },
      deep: true,
    },
    selectedModule: {
      handler() {
        this.getQuestions();
      },
      deep: true,
    },
    selectedQuestion: {
      handler() {
        this.getQuestions();
      },
      deep: true,
    },
  },
  methods: {
    resetModal() {
      this.levels = [];
      this.lessons = [];
      this.modules = [];
      this.questions = [];
      this.selectedLevel = null;
      this.selectedLesson = null;
      this.selectedModule = null;
      this.selectedQuestion = null;
      this.$emit('reset');
    },
    async getPrepare() {
      try {
        const { meta, data, error } = await this.$api.get(
          '/Quiz/PrepareSearchAvailableQuiz',
        );
        if (!meta.success) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.levels = data.lessonLevels;
        this.lessons = data.lessonModules;
        this.modules = data.lessons;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    async getQuestions() {
      try {
        const { meta, data, error } = await this.$api.get(
          '/Quiz/SearchAvailableQuiz',
          {
            params: this.searchQuestion,
          },
        );
        if (!meta.success) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.questions = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequireAnswer && !this.showErrorAnswers) {
          this.submit();
        }
        return;
      });
    },
    submit() {
      const questions = this.storedQuestions.map((el) => {
        return {
          ...el,
          code: el.code.includes('_') ? el.code.split('_')[1] : el.code,
        };
      });
      if (questions.find((el) => el.code === this.selectedQuestion.code)) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Mã câu hỏi đã tồn tại.',
          preventDuplicates: true,
        });
      }
      this.selectedQuestion.code = `${this.generateReferralCode(6)[0]}_${
        this.selectedQuestion.code
      }`;
      this.$emit('add-question-avaiable', this.selectedQuestion);
      this.resetModal();
    },
  },
};
</script>
<style lang=""></style>
